import React, { Component } from 'react'
import Slider from 'react-slick'
import RecentProjectsData from './RecentProjectsData'
// import RecentProjectData from'./RecentProjectsData'
import { useParams } from 'react-router'
import { FaHandPointLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import MainComponent from './main';
import Image_1 from '../../assets/works/optimo.png';
import Image_2 from '../../assets/works/BranderMine-01 1.png';
import Image_3 from '../../assets/works/1 1.png';
import Image_4 from '../../assets/works/IOT-04 1.png';





function RecentProjects({ slider, data }) {
  const { id } = useParams()

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: true,
  }

  function next() {
    slider.slickNext()
  }
  function previous() {
    slider.slickPrev()
  }
  return (
    <div>
      <section
        className="landingSection homePage__slider"
        style={{ zIndex: '5' }}
      >
        <div className="sectionNumber flex align-center justify-center white runAnimation fadeIn run">
          <p>01</p>
        </div>
        <div className="wrapper flex direction-column align-start homePage__slider--wrap">
          {/**/}
          <h2 className="landingSection__title flex align-center runAnimation fadeInBottomRight run">
            works
          </h2>
          {/**/}
          <p className="landingSection__head runAnimation fadeInUp d-1 run">
            Recent projects
          </p>
          <div className="homePage__slider__controls flex align-center">
            <button className="slideControl prev pointer disabled">
              <svg
                width={40}
                height={28}
                viewBox="0 0 40 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M39.7559 13.4107L27.2559 0.910664C26.9303 0.585117 26.403 0.585117 26.0775 0.910664C25.752 1.23621 25.752 1.76356 26.0775 2.08902L37.155 13.1665H0.833359C0.372734 13.1665 0 13.5393 0 13.9999C0 14.4605 0.372734 14.8332 0.833359 14.8332H37.155L26.0775 25.9107C25.752 26.2362 25.752 26.7636 26.0775 27.089C26.2402 27.2518 26.4535 27.3332 26.6667 27.3332C26.8799 27.3332 27.0931 27.2518 27.2559 27.089L39.7559 14.589C40.0814 14.2636 40.0814 13.7362 39.7559 13.4107Z"
                  fill="url(#paint0_linear)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="6.65755"
                    y1="22.9962"
                    x2="27.9766"
                    y2="2.02273"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#0084FF" />
                    <stop offset={1} stopColor="#2BF6E7" />
                  </linearGradient>
                </defs>
              </svg>
            </button>
            <div className="text pointer animateLink">Next project</div>
            <button className="slideControl pointer" onClick={next}>
              <svg
                width={40}
                height={28}
                viewBox="0 0 40 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M39.7559 13.4107L27.2559 0.910664C26.9303 0.585117 26.403 0.585117 26.0775 0.910664C25.752 1.23621 25.752 1.76356 26.0775 2.08902L37.155 13.1665H0.833359C0.372734 13.1665 0 13.5393 0 13.9999C0 14.4605 0.372734 14.8332 0.833359 14.8332H37.155L26.0775 25.9107C25.752 26.2362 25.752 26.7636 26.0775 27.089C26.2402 27.2518 26.4535 27.3332 26.6667 27.3332C26.8799 27.3332 27.0931 27.2518 27.2559 27.089L39.7559 14.589C40.0814 14.2636 40.0814 13.7362 39.7559 13.4107Z"
                  fill="url(#paint1_linear)"
                />
                <defs>
                  <linearGradient
                    id="paint1_linear"
                    x1="6.65755"
                    y1="22.9962"
                    x2="27.9766"
                    y2="2.02273"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#0084FF" />
                    <stop offset={1} stopColor="#2BF6E7" />
                  </linearGradient>
                </defs>
              </svg>
            </button>
          </div>
        </div>
        <span className="sections__scrollWrap" />





        <div className="homePage__slider__item--wrap regular  desk-hide tab-hide" style={{ height: 'inherit' }}>


          {/* Project 1 */}
          <div className="homePage__slider__item runAnimation fadeInUp sm run ">
            <div className="flex align-end homePage__slider__item__container">
              <figure className="homePage__slider__item__img--wrap pointer isLink">
                <img src={Image_1} alt="Star trans logistic" className="homePage__slider__item__img isLink" />
              </figure><div className="flex direction-column align-start homePage__slider__item__info">
                <h3 className="homePage__slider__item__title flex align-center">Optimo
                  Investments</h3>
                <p className="homePage__slider__item__description">
                  Development of the Trading and investment Company
                </p>
                <a href="/en/portfolio/startranslog" className="homePage__slider__item__link inline-flex align-center animateLink">
                  show case
                  <svg width={15} height={15} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="arrow"><g clipPath="url(#clip123)"><path d="M14.9084 7.27906L10.2209 2.59156C10.0989 2.46948 9.90111 2.46948 9.77906 2.59156C9.65698 2.71364 9.65698 2.91139 9.77906 3.03345L13.9331 7.18751H0.31251C0.139775 7.18751 0 7.32729 0 7.50002C0 7.67276 0.139775 7.81253 0.31251 7.81253H13.9331L9.77906 11.9666C9.65698 12.0886 9.65698 12.2864 9.77906 12.4085C9.84009 12.4695 9.92007 12.5 10 12.5C10.08 12.5 10.1599 12.4695 10.221 12.4085L14.9085 7.72095C15.0305 7.5989 15.0305 7.40114 14.9084 7.27906Z" fill="black" /></g><defs><clipPath id="clip123"><rect width={15} height={15} fill="white" /></clipPath></defs></svg>
                </a>
              </div>
            </div>
          </div>
          {/* Project 1 End */}

          {/* Project 2 */}
          <div className="homePage__slider__item runAnimation fadeInUp sm run">
            <div className="flex align-end homePage__slider__item__container">
              <figure className="homePage__slider__item__img--wrap pointer isLink">
                <img src={Image_2} alt="Star trans logistic" className="homePage__slider__item__img isLink" />
              </figure><div className="flex direction-column align-start homePage__slider__item__info">
                <h3 className="homePage__slider__item__title flex align-center">BranderMine</h3>
                <p className="homePage__slider__item__description">
                  Development of the Logo and Name Generator Company
                </p>
                <a href="/en/portfolio/startranslog" className="homePage__slider__item__link inline-flex align-center animateLink">
                  show case
                  <svg width={15} height={15} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="arrow"><g clipPath="url(#clip123)"><path d="M14.9084 7.27906L10.2209 2.59156C10.0989 2.46948 9.90111 2.46948 9.77906 2.59156C9.65698 2.71364 9.65698 2.91139 9.77906 3.03345L13.9331 7.18751H0.31251C0.139775 7.18751 0 7.32729 0 7.50002C0 7.67276 0.139775 7.81253 0.31251 7.81253H13.9331L9.77906 11.9666C9.65698 12.0886 9.65698 12.2864 9.77906 12.4085C9.84009 12.4695 9.92007 12.5 10 12.5C10.08 12.5 10.1599 12.4695 10.221 12.4085L14.9085 7.72095C15.0305 7.5989 15.0305 7.40114 14.9084 7.27906Z" fill="black" /></g><defs><clipPath id="clip123"><rect width={15} height={15} fill="white" /></clipPath></defs></svg>
                </a>
              </div>
            </div>
          </div>
          {/* Project 2 End */}


          {/* Project 3 */}
          <div className="homePage__slider__item runAnimation fadeInUp sm run">
            <div className="flex align-end homePage__slider__item__container">
              <figure className="homePage__slider__item__img--wrap pointer isLink">
                <img src={Image_3} alt="Star trans logistic" className="homePage__slider__item__img isLink" />
              </figure><div className="flex direction-column align-start homePage__slider__item__info">
                <h3 className="homePage__slider__item__title flex align-center">NorthShore Learning Center</h3>
                <p className="homePage__slider__item__description">
                  Development of the Child Care
                  web site in Australia
                </p>
                <a href="/en/portfolio/startranslog" className="homePage__slider__item__link inline-flex align-center animateLink">
                  show case
                  <svg width={15} height={15} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="arrow"><g clipPath="url(#clip123)"><path d="M14.9084 7.27906L10.2209 2.59156C10.0989 2.46948 9.90111 2.46948 9.77906 2.59156C9.65698 2.71364 9.65698 2.91139 9.77906 3.03345L13.9331 7.18751H0.31251C0.139775 7.18751 0 7.32729 0 7.50002C0 7.67276 0.139775 7.81253 0.31251 7.81253H13.9331L9.77906 11.9666C9.65698 12.0886 9.65698 12.2864 9.77906 12.4085C9.84009 12.4695 9.92007 12.5 10 12.5C10.08 12.5 10.1599 12.4695 10.221 12.4085L14.9085 7.72095C15.0305 7.5989 15.0305 7.40114 14.9084 7.27906Z" fill="black" /></g><defs><clipPath id="clip123"><rect width={15} height={15} fill="white" /></clipPath></defs></svg>
                </a>
              </div>
            </div>
          </div>
          {/* Project 3 End */}



          {/* Project 4 */}
          <div className="homePage__slider__item runAnimation fadeInUp sm run">
            <div className="flex align-end homePage__slider__item__container">
              <figure className="homePage__slider__item__img--wrap pointer isLink">
                <img src={Image_4} alt="Star trans logistic" className="homePage__slider__item__img isLink" />
              </figure><div className="flex direction-column align-start homePage__slider__item__info">
                <h3 className="homePage__slider__item__title flex align-center">Electroligence Pvt LTD</h3>
                <p className="homePage__slider__item__description">
                Development of the industrial-grade  Machine Downtime monitoring Hardware firmware.
                </p>
                <a href="/en/portfolio/startranslog" className="homePage__slider__item__link inline-flex align-center animateLink">
                  show case
                  <svg width={15} height={15} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="arrow"><g clipPath="url(#clip123)"><path d="M14.9084 7.27906L10.2209 2.59156C10.0989 2.46948 9.90111 2.46948 9.77906 2.59156C9.65698 2.71364 9.65698 2.91139 9.77906 3.03345L13.9331 7.18751H0.31251C0.139775 7.18751 0 7.32729 0 7.50002C0 7.67276 0.139775 7.81253 0.31251 7.81253H13.9331L9.77906 11.9666C9.65698 12.0886 9.65698 12.2864 9.77906 12.4085C9.84009 12.4695 9.92007 12.5 10 12.5C10.08 12.5 10.1599 12.4695 10.221 12.4085L14.9085 7.72095C15.0305 7.5989 15.0305 7.40114 14.9084 7.27906Z" fill="black" /></g><defs><clipPath id="clip123"><rect width={15} height={15} fill="white" /></clipPath></defs></svg>
                </a>
              </div>
            </div>
          </div>
          {/* Project 4 End */}




        </div>




        <div
          data-v-3d1a4f76
          dir="ltr"
          className="homePage__slider__item--wrap slick-slider slick-initialized mob-hide"
        >
          <div data-v-3d1a4f76 className="slick-list">
            <div
              data-v-e4caeaf8
              data-v-3d1a4f76
              className="slick-track"
              style={{
                width: '2828px',
                opacity: 1,
                transform: 'translate3d(0px, 0px, 0px)',
              }}
            >
              <Slider ref={(c) => (slider = c)} {...settings}>
                {data.map((data, i) => {
                  return (
                    // <Link>
                    <div>
                      <MainComponent
                        data={RecentProjectsData}
                        shortDesc={data.desc}
                        shortName={data.name}
                        image={data.image}
                      />
                    </div>
                    // </Link>
                  )
                })}
              </Slider>
              {/* <div
                data-v-e4caeaf8
                tabIndex={-1}
                data-index={0}
                aria-hidden="false"
                className="slick-slide slick-active slick-current"
                style={{ outline: "none", width: "707px" }}
              >
                <div data-v-e4caeaf8>
                  <div
                    data-v-e4caeaf8
                    tabIndex={-1}
                    className="homePage__slider__item runAnimation fadeInUp sm run"
                    style={{ width: "100%", display: "inline-block" }}
                  >
                    <div
                      data-v-e4caeaf8
                      className="flex align-end homePage__slider__item__container"
                    >
                      <figure
                        data-v-e4caeaf8
                        className="homePage__slider__item__img--wrap pointer isLink"
                      >
                        <img
                          data-v-e4caeaf8
                          src="https://valmaxdigital.com.ua/dist/public/img/portfolio/en/slide_1.d127413.png"
                          alt="Star trans logistic"
                          className="homePage__slider__item__img isLink"
                        />
                      </figure>
                      <div
                        data-v-e4caeaf8
                        className="flex direction-column align-start homePage__slider__item__info"
                      >
                        <h3
                          data-v-e4caeaf8
                          className="homePage__slider__item__title flex align-center"
                        >
                          Star trans logistic
                        </h3>
                        <p
                          data-v-e4caeaf8
                          className="homePage__slider__item__description"
                        >
                          Development of a site for a transport and forwarding
                          company
                        </p>
                        <a
                          href="/en/portfolio/startranslog"
                          className="homePage__slider__item__link inline-flex align-center animateLink"
                          data-v-e4caeaf8
                        >
                          show case
                          <svg
                            width={15}
                            height={15}
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="arrow"
                          >
                            <g clipPath="url(#clip123)">
                              <path
                                d="M14.9084 7.27906L10.2209 2.59156C10.0989 2.46948 9.90111 2.46948 9.77906 2.59156C9.65698 2.71364 9.65698 2.91139 9.77906 3.03345L13.9331 7.18751H0.31251C0.139775 7.18751 0 7.32729 0 7.50002C0 7.67276 0.139775 7.81253 0.31251 7.81253H13.9331L9.77906 11.9666C9.65698 12.0886 9.65698 12.2864 9.77906 12.4085C9.84009 12.4695 9.92007 12.5 10 12.5C10.08 12.5 10.1599 12.4695 10.221 12.4085L14.9085 7.72095C15.0305 7.5989 15.0305 7.40114 14.9084 7.27906Z"
                                fill="black"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip123">
                                <rect width={15} height={15} fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="wrapper flex justify-end">
          <a
            href="/works"
            className="buttonSm white button__gradient homePage__blog__button runAnimation fadeInUp run"
          >
            <span className="buttonSm__bg white" />
            View all works
          </a>
        </div>
      </section>
    </div>
  )
}

export default RecentProjects
