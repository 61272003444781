import React from 'react'
import { Link } from 'react-router-dom'

export default function MainComponent({
  shortDesc,
  shortName,
  showCase,
  image,
  data,
}) {
  return (

    <div>


      <div
        data-v-e4caeaf8
        tabIndex={-1}
        data-index={0}
        aria-hidden="false"
        className="slick-slide slick-active slick-current"
        style={{ outline: 'none',marginRight:'20px' }}
      >
        <div data-v-e4caeaf8>
          <div
            data-v-e4caeaf8
            tabIndex={-1}
            className="homePage__slider__item runAnimation fadeInUp sm run"
            style={{ width: '100%', display: 'inline-block' }}
          >
            <div
              data-v-e4caeaf8
              className="flex align-end homePage__slider__item__container"
            >
              <figure
                data-v-e4caeaf8
                className="homePage__slider__item__img--wrap pointer isLink"
              >
                <img
                  data-v-e4caeaf8
                  src={image}
                  alt="Star trans logistic"
                  className="homePage__slider__item__img isLink"
                  style={{minWidth:"670px",maxWidth:"675px"}}
                />
              </figure>
              <div
                data-v-e4caeaf8
                className="flex direction-column align-start homePage__slider__item__info"
              >
                <h3
                  data-v-e4caeaf8
                  className="homePage__slider__item__title flex align-center"
                >
                  {shortName}
                </h3>
                <p
                  data-v-e4caeaf8
                  className="homePage__slider__item__description"
                >
                  {shortDesc}
                </p>
                 <a
                          href="/en/portfolio/startranslog"
                          className="homePage__slider__item__link inline-flex align-center animateLink"
                          data-v-e4caeaf8
                        >
                          show case
                          <svg
                            width={15}
                            height={15}
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="arrow"
                          >
                            <g clipPath="url(#clip123)">
                              <path
                                d="M14.9084 7.27906L10.2209 2.59156C10.0989 2.46948 9.90111 2.46948 9.77906 2.59156C9.65698 2.71364 9.65698 2.91139 9.77906 3.03345L13.9331 7.18751H0.31251C0.139775 7.18751 0 7.32729 0 7.50002C0 7.67276 0.139775 7.81253 0.31251 7.81253H13.9331L9.77906 11.9666C9.65698 12.0886 9.65698 12.2864 9.77906 12.4085C9.84009 12.4695 9.92007 12.5 10 12.5C10.08 12.5 10.1599 12.4695 10.221 12.4085L14.9085 7.72095C15.0305 7.5989 15.0305 7.40114 14.9084 7.27906Z"
                                fill="black"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip123">
                                <rect width={15} height={15} fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </a> 
              </div>
            </div>
          </div>
        </div>
      </div>






    </div>
  )
}
