import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
// import BlogsSection from './BlogsSection';
// import MainSection from './MainSection';
import HomeSection from "./WhatWeDoSection";
// import WhyUsSection from './WhyUsSection';
// import '../../components/Navbar/NavBar2.css'
// import './index.css';

// import DiscussionSection from './DiscussionSection';
// import RecentProjects from '../../components/RecentProjects';
// import RecentProjectsData from '../../components/RecentProjects/RecentProjectsData';

// import '../../components/Navbar/NavBar2.css'
import "../../components/Navbar/style.css";
export default function HomePage() {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  return (
    

        <div className="view homePage">
          <div className={sidebar ? "my-toggle" : ""}>
      <div className="mainSection flex direction-column mainSection__homePage">
        <Navbar showSidebar={showSidebar} sidebar={sidebar} buyhost={ true}/>
          {/* <MainSection/> */}

          <HomeSection />
          {/* <WhyUsSection /> */}

          {/* <RecentProjects data={RecentProjectsData}/> */}

          {/* <BlogsSection /> */}
          {/* <DiscussionSection/> */}
        </div>
      </div>
    </div>
  );
}
