/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react'
import * as FaIcons from 'react-icons/fa'
import * as AiIcons from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { NavBarData } from './NavBarData'

import logo from '../../assets/img/logo.svg'
// import logo from "../../assets/img/Logo(2).png";

import logo_header from '../../assets/icons/fortxcore_White.svg'
import logo_headerb from '../../assets/icons/fortxcore_Black.svg'

import './Navbar.css'
// import "./NavBar2.css";
import Button from '../button'
// import { IconContext } from 'react-icons';

import useLockBodyScroll from './LayoutLock'

function Navbar({ sidebar, showSidebar, buyhost, hide_button, headerblack }) {
  // const [sidebar, setSidebar] = useState(false);
  const [header, isheaderActive] = useState(false)
  const [scroll, setScroll] = useState(false)

  const handlescrolling = () => {
    if (window.scrollY >= 1) {
      setScroll(true)
    } else {
      setScroll(false)
    }
  }

  //wait i will show you the ware the loading screen on!

  window.addEventListener('scroll', handlescrolling)

  // if (sidebar) {
  //   useLockBodyScroll();
  //   isheaderActive(true)
  // }
  // const showSidebar = () => setSidebar(!sidebar);

  return (
    <div className={scroll ? 'my-hide' : ''}>
      {/* header--wrap blackAndWhite activeFistSection - Not Active */}

      {/* header--wrap black isOpenMenu activeFistSection - Open */}

      {/* Navi */}
      <div
        className={
          sidebar
            ? 'header--wrap black isOpenMenu activeFistSection'
            : 'header--wrap blackAndWhite activeFistSection'
        }
      >
        <div className="header flex align-center justify-between">
          <span className="header__logo flex align-center">
            <div className="flex align-center">
              <img
                src={logo}
                alt="fortxcore"
                className="header__logo__part-1 brand__logo"
              />

              {/* <span className="brand__name">fortxcore</span> */}
              {headerblack ? (
                <img src={logo_headerb} className="logo_header" />
              ) : (
                <img src={logo_header} className="logo_header" />
              )}
            </div>
          </span>
          <div className="header__links">
            {hide_button ? (
              <button className={'my-hide'}>CLIENT PORTAL</button>
            ) : (
              <button
                className={
                  sidebar
                    ? 'my-hide'
                    : 'button button__gradient discussProject animate animate__right buy_hosting_btn'
                }
              >
                CLIENT PORTAL
              </button>
            )}
            &nbsp; &nbsp;&nbsp; &nbsp;
            <a
              href="/works"
              className={
                sidebar ? 'my-hide' : 'header__links__portfolio white-text-bg blackAndWhite '
              }
            >
              portfolio
            </a>
            {/* Toggle Btn */}
            {/* Toggle eka click karapuwama mekata me class eka add karanna*/}
            {/*   header__burger black active */}
            {/* -------------------------------------------------------------- */}
            {/* Close eka Click kalama */}
            {/* header__burger black */}
            {/* --------------------------------------------------------- */}
            <button
              className={
                sidebar
                  ? 'header__burger black active'
                  : 'header__burger blackAndWhite'
              }
              onClick={showSidebar}
            >
              <span className="line ham-color" />
              <span className="line ham-color" />
              <span className="line ham-color" />

              {/* Menu eka In-Active wunama Methanta my-hide class eka add karala close text eka
              hide kranna */}
              <span className={sidebar ? 'text' : 'my-hide text'}>close</span>
            </button>
          </div>
          {/**/}
        </div>
      </div>
      {/* Navi End */}

      {/* <div className="open" onClick={showSidebar}>
        <Link to="#" className="name">
          <FaIcons.FaBars />
        </Link>
      </div> */}

      <div>
        <div class={sidebar ? `active menu ` : `menu`}>
          <div className="close" onClick={showSidebar}>
            {/* <Link to="#" className="menu-bars name">
              <span>
                close
              </span>
              <AiIcons.AiOutlineClose
                color="#FFFFFF"
              />
            </Link> */}
          </div>
          <div class="menu__wrapper flex align-center justify-between">
            <ul
              class="menu__links inline-flex direction-column align-start"
              onClick={showSidebar}
            >
              {NavBarData.map((data, i) => {
                return (
                  <li className="animate animate__left">
                    <Link to={data.path}>
                      <span className="menu__links__item animateLink pointer">
                        {data.title}
                      </span>
                    </Link>
                  </li>
                )
              })}
            </ul>

            <div className="menu__contacts flex direction-column align-center">
              <p className="contactUs">Contacts us:</p>
              <a
                href="tel:+380660257838"
                className="tel animate animate__right animateLink"
              >
                +94 (011) 708 00 13
              </a>
              <Link to="#" className="mail animate animate__right animateLink">
                support@fortxcore.com
              </Link>
              <Button name="discuss the project" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
