import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';

export const NavBarData = [
  {
    title: 'Home',
    path: '/',
  
  },
  {
    title: 'About',
    path: '/about',

  },
  {
    title: 'Works',
    path: '/works',
 
  },
  // {
  //   title: 'Blog',
  //   path: '/blog',
   
  // },
  {
    title: 'Contact',
    path: '/contact',

  },

];
