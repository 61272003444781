/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";

import Svg1 from "../../assets/img/95326591f76b66b7176803c36f2e1411.svg";
import Svg2 from "../../assets/img/2b34a13fe045cf7901a224f19a15c9d8.svg";
import Svg4 from "../../assets/img/7d39b955139466e8a5d20690d6dbe143.svg";
import Svg5 from "../../assets/img/36547e213b66ab504bd1e4b565e1f72d.svg";
import Svg6 from "../../assets/img/d4f7e724778153e98ab8089b72d67b1b.svg";
import Svg7 from "../../assets/img/d84fd2e286f18ef16d7107724229ef55.svg";
import Svg8 from "../../assets/img/ea14b0e966a7a3c4bd95e270838e6b33.svg";
import logo from "../../assets/img/logo.svg";

import logo_footer from "../../assets/icons/fortxcore_Black.svg";

import ast_robo from "../../assets/img/OBJECTS.png";

import ovel from "../../assets/img/Group 6.png";

// import Png1 from "../../assets/img/column.7d9da0b.png";

import Png1 from "../../assets/img/Layer 4.png";

import Png2 from "../../assets/img/head_bottom.ca3fbdc.png";
import Png3 from "../../assets/img/head_center.7b7d28b.png";
import Png4 from "../../assets/img/head_top.31d4c84.png";
// import Png5 from "../../assets/img/manStatue.aa087e3.png";
import Png5 from "../../assets/img/astronaut phone [Converted]-01 1.png";
import Png8 from "../../assets/img/preview.6162592.png";

import galaxy from "../../assets/img/galaxy.png";

import Png13 from "../../assets/img/starlightNight.787348c.png";

import useLockBodyScroll from "../../components/Navbar/LayoutLock";


import RecentProjects from "../../components/RecentProjects";
import RecentProjectsData from "../../components/RecentProjects/RecentProjectsData";
import AOS from "aos";
import "aos/dist/aos.css";
// import "../../aos/dist/aos.css";
// import "../../components/Navbar/bootstrap.css";

export default function HomeSection() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const [scroll, setScroll] = useState(false);

  const handlescrolling = () => {
    if (window.scrollY >= 1) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  //wait i will show you the ware the loading screen on!

  window.addEventListener("scroll", handlescrolling);

  return (
    <div>
      {/* Home Section Start */}
      <section
        className={
          scroll
            ? "first_section--wrap flex direction-column homePage__section first active"
            : "first_section--wrap flex direction-column homePage__section first"
        }
      >
        <div className="first_section__scroll--wrap">
          <span className="first_section__scroll__line" />
          <span className="first_section__scroll__line" />
          <span className="first_section__scroll__line" />
          <span className="first_section__scroll__mouse pointer">
            <span></span>
          </span>
          <span className="first_section__scroll__text pointer">
            scroll down
          </span>
        </div>
        <div className="first_section flex align-center">
          <div className="flex align-center fullFlex">
            <div className="first__content flex direction-column align-center ">
              <h1 className="flex direction-column first__content__name"  >
                <span className="first__content__name__digital welcome">
                  welcome
                </span>

                <span className="glitch--wrap ">
                  <span data-text="fortxcore" className="glitch">
                    fortxcore
                  </span>
                </span>
              </h1>
              <span className="py-4 "></span>

              <p className="first__content__name__text head-text">
                fortXcore provides software and mobile application development,
                web development & ROI driven digital marketing services on
                global scale!
              </p>
              <object
                data={Svg6}
                type="image/svg+xml"
                className="figure num_1 mounted"
              />
              <object
                data={Svg2}
                type="image/svg+xml"
                className="figure num_2 mounted"
              />
              <object data={Svg7} type="image/svg+xml" className="ellipse" />
            </div>
            <div className="head--wrap flex justify-center">
              <div className="head mounted">
                {/* <img src={galaxy} className="head__img" /> */}

                <object
                  data={Png4}
                  type="image/png"
                  className="head__img top" style={{visibility:"hidden"}}
                />

                <img
                  src={galaxy}
                  
                  className="head__img center py-10 head__img center"
                />

                <object
                  data={Png2}
                  type="image/png"
                  className="head__img bottom" style={{visibility:"hidden"}}
                />

                {/* <object
                  data={Png4}
                  type="image/png"
                  className="head__img top"
                />
                <object
                  data={Png3}
                  type="image/png"
                  className="head__img center"
                />
                <object
                  data={Png2}
                  type="image/png"
                  className="head__img bottom"
                /> */}

                <ul className="dots--wrap runAnimation fadeIn d-2 dots_1 head__dots run" style={{display:"none"}}>
                  <li className="dots__line flex">
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                  </li>
                  <li className="dots__line flex">
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                  </li>
                  <li className="dots__line flex">
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                  </li>
                  <li className="dots__line flex">
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                  </li>
                  <li className="dots__line flex">
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                  </li>
                  <li className="dots__line flex">
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                  </li>
                  <li className="dots__line flex">
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                  </li>
                </ul>
                <ul className="dots--wrap runAnimation fadeIn d-2 dots_2 head__dots run" style={{display:"none"}}>
                  <li className="dots__line flex">
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                  </li>
                  <li className="dots__line flex">
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                  </li>
                  <li className="dots__line flex">
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                  </li>
                  <li className="dots__line flex">
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                  </li>
                  <li className="dots__line flex">
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                  </li>
                  <li className="dots__line flex">
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                  </li>
                  <li className="dots__line flex">
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                  </li>
                </ul>
              </div>
              {/* <object data={Svg7} type="image/svg+xml" className="ellipse" /> */}
              <object
                type="image/svg+xml"
                data={Svg6}
                className="figure num_1 mounted"
              />
              <object
                type="image/svg+xml"
                data={Svg2}
                className="figure num_2 mounted"
              />
              <object
                type="image/svg+xml"
                data={Svg4}
                className="figure num_3 mounted"
              />
              <object
                type="image/svg+xml"
                data={Svg5}
                className="figure num_4 mounted"
              />
              <object
                type="image/svg+xml"
                data={Svg8}
                className="figure num_5 mounted"
              />
            </div>
          </div>
        </div>
      </section>
      {/* Home Section End */}

      {/* 1 */}
      <div className={scroll ? "sections--wrap active" : "sections--wrap"}>
        {/* Recent Project Section */}

        <RecentProjects data={RecentProjectsData} />
        {/* <RecentProjects /> */}

        {/* Recent Project Section Ended */}

        {/* What We Do */}
        <section className="landingSection black homePage__services__container">
          <span className="sections__scrollWrap" />
          <div
            style={{ left: "60px" }}
            className="sectionNumber flex align-center justify-center black runAnimation fadeIn run"
          >
            <p>02</p>
          </div>
          <div className="wrapper flex direction-column align-start">
            <div  className="">
              <h2 className="landingSection__title flex align-center runAnimation fadeInBottomRight run">
                Services
              </h2>
            </div>

            <div  className="hide">
              <p className="landingSection__head runAnimation fadeInUp d-1 run">
                What we do
              </p>
            </div>

            <div className="container-fluid py-3" >
              <div className="row">
                {/* 1st  */}
                <div className="col-12 col-md-6">
                  <h2 className="homePage__services__item__title" >
                    WEB-DEVELOPMENT
                  </h2>
                  <p className="homePage__services__item__text">
                    FortXcore undertakes all kinds of Web site development & Web
                    Application Development Projects regardless of the
                    complexity of the scope. We provide cutting-edge solutions
                    using state-of-the-art technologies, ensuring the utmost
                    comfort for the end-user. As a result, we will be paving the
                    way for the profitability of your Business.
                  </p>
                </div>
                {/* 1st end */}

                {/* 2nd */}
                <div className="col-12 col-md-6">
                  <h2 className="homePage__services__item__title">
                    UI-UX SOLUTIONS
                  </h2>
                  <p className="homePage__services__item__text">
                    At FortXcore, we take UI/UX Seriously. With highly creative
                    senses & technological competencies, FortXcore provides
                    UI/UX solutions above the modern-day industry
                    standards,setting up new heights for the user experience &
                    satisfaction. These Modern-day UI solutions are aimed to
                    uplift your businesses in the long run.
                  </p>
                </div>
                {/* 2nd end */}

                <span className="py-5"></span>
                <span className="py-5"></span>

                <div className="col-12 col-md-6">
                  <div className="row">
                    {/* 3rd */}
                    <div className="col-12">
                      <h2 className="homePage__services__item__title">
                        MOBILE APP-DEVELOPMENT
                      </h2>
                      <p className="homePage__services__item__text">
                        FortXcore provides Mobile App Development for all sorts
                        of businesses & brands to connect with their end-users
                        for increased profitability. FortXcore develops
                        user-friendly mobile apps for iOS, Android & Windows
                        Mobile Platforms with highly responsive layouts,
                        ensuring the ability to uphold its qualities while
                        meeting high end-user demands.
                      </p>
                    </div>
                    {/* 3rd end */}
                    <span className="py-4"></span>
                    {/* 4rd */}
                    <div className="col-12">
                      <h2 className="homePage__services__item__title">
                        Search Engine Optimizations (SEO) & Search Engine
                        Marketing (SEM)
                      </h2>
                      <p className="homePage__services__item__text">
                        SEO & SEM are on our regular task list! FortXcore has
                        up-to-date technical skills & Knowledge to take your
                        website to higher levels of SERP (Search Engine Result
                        Page) with comprehensive SEO techniques & methods. We
                        will execute result-driven SEM campaigns with perfect
                        targeting to increase the visibility & Click rates to
                        fulfill your business objectives.
                      </p>
                    </div>
                    {/* 4rd end */}
                  </div>
                </div>

                {/* Images */}

                <div className="col-12 col-md-6">
                  <img src={Png5} />
                </div>
              </div>
              {/* Images End */}
            </div>

            <div className="homePage__services--wrap">
              <object data={Svg7} type="image/svg+xml" className="ellipse" />
              <object
                data={Svg2}
                type="image/png"
                className="wakeUpSm figure2 square_why_us"
              />
              {/* <object
                data={Svg4}
                type="image/png"
                className="figure3 wakeUpSm"
              /> */}
              <span class="py-5"></span>
              <div className="figure5 wakeUpSm">
                <object
                  data={ovel}
                  type="image/png"
                  className="figure5__item"
                />
              </div>
            </div>

            {/*             
            <div className="homePage__services--wrap" >

              <div className="homePage__services flex align-start">
                <div className="flex direction-column homePage__services__left">
                  <div className="homePage__services__item mb num_1 runAnimation fadeInBottomRight run">
                    <h3
                      className="homePage__services__item__title"
                      data-aos="slide-left"
                    >
                      Web Development
                    </h3>
                    <p
                      className="homePage__services__item__text"
                      data-aos="slide-right"
                    >
                      FortXcore undertakes all kinds of Web site development &
                      Web Application Development Projects regardless of the
                      complexity of the scope. We provide cutting-edge solutions
                      using state-of-the-art technologies, ensuring the utmost
                      comfort for the end-user. As a result, we will be paving
                      the way for the profitability of your Business.
                    </p>
                  </div>

                  <div className="homePage__services__item num_3 runAnimation fadeInBottomRight run">
                    <h3
                      className="homePage__services__item__title"
                      data-aos="slide-right"
                    >
                      Mobile App Development
                    </h3>
                    <p
                      className="homePage__services__item__text"
                      data-aos="slide-up"
                    >
                      FortXcore provides Mobile App Development for all sorts of
                      businesses & brands to connect with their end-users for
                      increased profitability. FortXcore develops user-friendly
                      mobile apps for iOS, Android & Windows Mobile Platforms
                      with highly responsive layouts, ensuring the ability to
                      uphold its qualities while meeting high end-user demands.
                    </p>
                  </div>

                  <div className="homePage__services__item num_3 runAnimation fadeInBottomRight run">
                    <h3
                      className="homePage__services__item__title pt-5"
                      data-aos="slide-right"
                    >
                      Search Engine Optimizations (SEO) & Search Engine
                      Marketing (SEM)
                    </h3>
                    <p
                      className="homePage__services__item__text"
                      data-aos="slide-up"
                    >
                      SEO & SEM are on our regular task list! FortXcore has
                      up-to-date technical skills & Knowledge to take your
                      website to higher levels of SERP (Search Engine Result
                      Page) with comprehensive SEO techniques & methods. We will
                      execute result-driven SEM campaigns with perfect targeting
                      to increase the visibility & Click rates to fulfill your
                      business objectives.
                    </p>
                  </div>
                </div>

                <div className="homePage__services__item ml d-1 num_2 runAnimation fadeInBottomRight run">
                  <h3
                    className="homePage__services__item__title"
                    data-aos="slide-left"
                  >
                    Social Media Marketing
                  </h3>
                  <p
                    className="homePage__services__item__text"
                    data-aos="slide-up"
                  >
                    FortXcore provides comprehensive Social Media Marketing
                    Solutions focusing on Reach, Brand Awareness, Leads
                    Generation, and Profitable Conversions through online &
                    offline data point integrations for your Business.
                    FortXcore’s scope of Social Media & Digital Marketing is far
                    beyond mere vanity metrics & more towards actual ROMI
                    (Return on Marketing Spend).
                  </p>

                  <h3 className="homePage__services__item__title pt-5 mt-5">
                    UI/UX Solutions
                  </h3>
                  <p className="homePage__services__item__text">
                    At FortXcore, we take UI/UX Seriously. With highly creative
                    senses & technological competencies, FortXcore provides
                    UI/UX solutions above the modern-day industry standards,
                    setting up new heights for the user experience &
                    satisfaction. These Modern-day UI solutions are aimed to
                    uplift your businesses in the long run.
                  </p>

                  <h3 className="homePage__services__item__title pt-5 ">
                    Arduino Programing
                  </h3>

                  <p className="homePage__services__item__text">
                    FortXcore Sri Lanka offers cutting-edge Arduino Programming
                    solutions for businesses, organizations, or individuals in
                    need of 100% accurate, error-free Arduino solutions in
                    hardware programming. FortXcore will work with you to debug
                    the Arduino program until it works the way you want it. Even
                    if you haven’t any previous exposure to Arduino programming
                    & implementation, FortXcore will help you in solving
                    real-life problems & creating profitable business
                    opportunities through it.
                  </p>
                </div>
              </div>
              




              <object
                data={Png5}
                type="image/png"
                className="homePage__services__img runAnimation fadeInUp run"
              />
              <object data={Svg7} type="image/svg+xml" className="ellipse" />
              <object
                data={Svg2}
                type="image/png"
                className="wakeUpSm figure2"
              />
              <object
                data={Svg4}
                type="image/png"
                className="figure3 wakeUpSm"
              />
              <div className="figure5 wakeUpSm">
                <object
                  data={ovel}
                  type="image/png"
                  className="figure5__item"
                />
              </div>
            </div> */}
          </div>
        </section>
        {/* What We Do End */}

        {/* Why Us  */}
        <section className="landingSection homePage__whyAre">
        <object data={Svg7} type="image/svg+xml" className="myElipse" />
          <section className="withColumn--wrap">
            <div className="wrapper withColumn flex align-center">
              {/* <ul className="dots--wrap runAnimation fadeIn d-2 withColumn__dots run">
                <li className="dots__line flex">
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                </li>
                <li className="dots__line flex">
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                </li>
                <li className="dots__line flex">
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                </li>
                <li className="dots__line flex">
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                </li>
              </ul> */}
              <div className="withColumn__img robo-section">
                <img
                  src={Svg1}
                  
                  className="rectangle wakeUpSm"/>
                <img
                  src={Png1}
                  className="column runAnimation fadeInUp run robot"
                />
              </div>
              <div >
                <p className="withColumn__text runAnimation fadeInUp run ">
                  Design is not just what it looks like and feels like. Design
                  is how it works.
                </p>
              </div>
            </div>
          </section>
          <span className="sections__scrollWrap" />
          <div
            style={{ left: "60px" }}
            className="sectionNumber flex align-center justify-center white runAnimation fadeIn run"
          >
            <p>03</p>
          </div>
          <div className="wrapper flex direction-column align-start">
            <h2
              className="landingSection__title flex align-center runAnimation fadeInBottomRight run"
              
            >
              Our mission
            </h2>

            {/* <div className="container">
              <div className="col-6">
                
                <div className="col-12">
                  <h1 className="why_us_headings">Professional Team</h1>
                </div>

                <div className="col-12 py-5 ">
                  FortXcore team consists of well-known names in the industry
                  with years of practical experience in what we do. We ensure
                  that the competencies & skills of our team members are
                  regularly updated, tested &challenged to keep them sharp &
                  constant!
                </div>

                <div className="col-12">
                  <h1 className="why_us_headings">Individual Solutions</h1>
                </div>

                <div className="col-12 py-5 ">
                  FortXcore believes in thematic approaches in fulfilling client
                  requirements. We work hard to understand the problems or
                  opportunities the client presents us with, followed by
                  thorough research, which leads to a customized & thematic,
                  modern-day solution.
                </div>
              </div>

              <div className="col-6">
                <img src={ast_robo} className="ast-img" />
              </div>
            </div>

              
              <div className="col-6">
                <h2 className="why_us_headings"> Modern technologoies</h2>

                <p>
                  FortXcore uses the most advance & cutting-edge tech. We
                  believe in setting up new standards for every work we do by
                  thinking ahead of time. Businesses do invest in their tech to
                  use it for the future. Hence, we ensure that up-to-date
                  technology is used.
                </p>
              </div>

              <div className="col-6">
                <h2 className="why_us_headings"> Modern technologoies</h2>
              </div> */}

            {/* <object data={Svg7} type="image/svg+xml" className="myElipse" /> */}


            <div className="container-fluid pt-5">
              <div className="row">


                <div className="col-12 pt-2 pb-5">
                  <h5 className="why_us_head_text pb-4"> Why us </h5>
                  

                  {/* Text row */}
                  <p className="why_us_2nd_text pb-3 collapse__text">
                    At FortXcore, we believe in developing & providing
                    futuristic solutions by putting our hearts & minds into what
                    we do, regardless of the service we offer. With the COVID-19
                    global pandemic in effect, the demand for IT products &
                    services has increased dramatically. We believe that
                    Business worldwide invests in tech & marketing, looking
                    forward to being profitable & thrive in these unpredictable
                    business environments. As FortXcore understands this vital
                    fact, we are geared up to take these challenging tasks
                    head-on to meet & exceed the expectations of our clients.
                  </p>
                </div>

                {/* Row 1 */}
                <div className="col-12 col-md-6">
                  <div className="row gy-5">
                    {/* Text */}
                    <div className="col-12">
                      <h2 className="why_us_headings">
                        <span class="homePage__whyAre__list__item__counter">
                          01 &nbsp;
                        </span>
                        Professional team{" "}
                      </h2>

                      <p className="collapse__text">
                        FortXcore team consists of well-known names in the
                        industry with years of practical experience in what we
                        do. We ensure that the competencies & skills of our team
                        members are regularly updated, tested &challenged to
                        keep them sharp & constant!
                      </p>
                    </div>
                    {/* Text End */}

                    {/* Text */}
                    <div className="col-12">
                      <h2 className="why_us_headings ">
                        <span class="homePage__whyAre__list__item__counter">
                          02 &nbsp;
                        </span>
                        Indiviual solutions
                      </h2>

                      <p className="collapse__text">
                        FortXcore believes in thematic approaches in fulfilling
                        client requirements. We work hard to understand the
                        problems or opportunities the client presents us with,
                        followed by thorough research, which leads to a
                        customized & thematic, modern-day solution.
                      </p>
                    </div>
                    {/* Text End */}
                  </div>
                </div>
                {/* ENd */}

                {/*Img  */}
                <div className="col-12 col-md-6">
                  <div className="row d-flex justify-content-center align-items-center">

                  <img src={Svg6} className="trinko-why-us" />

                  <img src={Svg8} className="cylinder-why-us " />

                    <img src={ast_robo} />
                    
                  </div>
                </div>
                {/* Img ENd */}

                {/* Line Spacing */}
                <span className="py-5"></span>
                
                {/* Line Spacing end*/}

                {/* 3rd Section */}
                <div className="col-12 col-md-6">
                  <h2 className="why_us_headings ">
                    <span class="homePage__whyAre__list__item__counter">
                      03 &nbsp;
                    </span>
                    Mordern technologies
                  </h2>

                  <p className="collapse__text">
                    FortXcore uses the most advance & cutting-edge tech. We
                    believe in setting up new standards for every work we do by
                    thinking ahead of time. Businesses do invest in their tech
                    to use it for the future. Hence, we ensure that up-to-date
                    technology is used.
                  </p>
                </div>

                {/* 3rd section end */}

                {/* 4th Section */}
                <div className="col-12 col-md-6">
                  <h2 className="why_us_headings ">
                    <span class="homePage__whyAre__list__item__counter">
                      04 &nbsp;
                    </span>
                    Meeting Deadlines
                  </h2>

                  <p className="collapse__text">
                    We know how precious time is for our clients. Therefore,
                    FortXcore uses best project management practices, Efficient
                    workflow management systems, follow-up mechanisms & Constant
                    updates to ensure the timely delivery of projects we
                    undertake.
                  </p>
                </div>

                {/* 4th section end */}
              </div>
            </div>
          </div>
        </section>
        {/* Why Us End */}

        {/* Discuss Section */}

        <section
          className="feedback--wrap container"
          style={{ maxWidth: "100%" }}
        >
          <span className="sections__scrollWrap" />
          <object data={Svg7} type="image/svg+xml" className="ellipse" />
          <ul className="dots--wrap runAnimation fadeIn d-2 feedback__dots run">
            <li className="dots__line flex">
              <span className="dot" />
              <span className="dot" />
              <span className="dot" />
              <span className="dot" />
              <span className="dot" />
              <span className="dot" />
            </li>
            <li className="dots__line flex">
              <span className="dot" />
              <span className="dot" />
              <span className="dot" />
              <span className="dot" />
              <span className="dot" />
              <span className="dot" />
            </li>
            <li className="dots__line flex">
              <span className="dot" />
              <span className="dot" />
              <span className="dot" />
              <span className="dot" />
              <span className="dot" />
              <span className="dot" />
            </li>
            <li className="dots__line flex">
              <span className="dot" />
              <span className="dot" />
              <span className="dot" />
              <span className="dot" />
              <span className="dot" />
              <span className="dot" />
            </li>
          </ul>
          <div className="wrapper feedback flex align-center">
            <div className="flex direction-column feedback__text--wrap align-start">
              <div >
                <p className="feedback__text runAnimation fadeInUp run">
                  Let’s explore your business opportunities together!
                </p>
              </div>
              <div >
                <button className="button button__gradient runAnimation fadeInUp run">
                  discuss the project
                </button>
              </div>
            </div>
            <div className="flex align-center direction-column feedback__links runAnimation fadeInUp run">
              <a
                target="_blank"
                href="tel:+380660257838"
                className="tel animateLink"
               
              >
                {/* +94 (011) 708 00 13  */}
              </a>
              <a
                target="_blank"
                href="mailto:hello@.com.ua"
                className="mail animateLink"
              
              >
                support@fortxcore.com 
              </a>
            </div>
          </div>
        </section>

        {/* Discuss Secion End */}

        {/* Start Footer */}
        

        <footer className="footer--wrap">
          <span className="footer__line--wrap wrapper my-line">
            <span className="footer__line" />
          </span>
          <div className="flex justify-between align-center footer">
            <div className="flex align-center footer__left">
              <a href="/en" className="header__logo footer__logo active">
                <div className="flex align-center">
                  <img
                    src={logo}
                    alt="Valmax Digital"
                    className="header__logo__part-1"
                  />
                  <span className="brand__name__footer">fortxcore</span>
                </div>
              </a>
              <p className="footer__copy">© 2021 FortXcore Software </p>
              <a href="/en/privacy" className="footer__link">
                Privacy policy
              </a>
            </div>
            <ul className="flex align-center footer__social--wrap" style={{display:"none"}}>
              <li className="footer__social">
                <a
                  target="_blank"
                  href="viber://"
                  className="social__links__link flex align-center justify-center viber"
                >
                  <svg
                    className="svg"
                    width={17}
                    height={17}
                    viewBox="0 0 17 17"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9.2156 1.57162C12.4295 1.67843 15.3216 4.64332 15.2824 7.79277C15.2769 8.22709 15.6245 8.58288 16.0578 8.58838C16.0609 8.58838 16.0648 8.58838 16.068 8.58838C16.4965 8.58838 16.8473 8.24359 16.8528 7.8124C16.903 3.78645 13.3587 0.13748 9.26819 0.00239161C8.83967 -0.0329514 8.47237 0.328332 8.45746 0.761087C8.44333 1.19463 8.78238 1.55748 9.2156 1.57162Z" />
                    <path d="M9.0298 4.18459C11.0288 4.42099 12.6424 6.09389 12.6267 7.91445C12.6228 8.34877 12.9712 8.70299 13.4045 8.70692C13.4068 8.70692 13.4092 8.70692 13.4115 8.70692C13.8416 8.70692 14.1924 8.35977 14.1964 7.92859C14.2183 5.33049 11.9831 2.95151 9.21345 2.62479C8.78572 2.57059 8.3933 2.88161 8.34229 3.3128C8.29206 3.7432 8.59971 4.13432 9.0298 4.18459Z" />
                    <path d="M15.7368 11.5035L15.5108 11.3307C15.0988 11.0056 14.6679 10.7103 14.2512 10.4252L13.9733 10.2343C13.2089 9.70809 12.4814 9.50546 11.8143 9.63269C11.1872 9.75207 10.6433 10.1487 10.1983 10.81C10.0931 10.9647 9.99344 11.1132 9.53824 10.9105C8.74085 10.5563 8.05255 10.1079 7.49218 9.57771L6.1195 7.6205C5.88798 7.07857 6.01434 6.916 6.32905 6.7063C6.72618 6.44083 7.55182 5.8887 7.49061 4.78128C7.42861 3.66366 5.22166 0.709774 4.16684 0.330426C3.68652 0.156853 3.1858 0.152926 2.67801 0.322572C1.53059 0.70192 0.703373 1.38286 0.287412 2.29235C-0.111283 3.16415 -0.0948013 4.1726 0.334502 5.20618C2.53125 10.5013 6.4562 14.3734 12.0003 16.7154C12.2859 16.8364 12.5787 16.9 12.7725 16.9432L12.9052 16.973C12.9774 16.9927 13.0527 16.9997 13.1273 16.9997C14.737 16.9997 16.3773 15.5499 16.849 14.2375C17.3889 12.7405 16.3545 11.9661 15.7368 11.5035ZM15.3711 13.705C15.0713 14.5399 13.9937 15.381 13.1924 15.4266C13.1657 15.4211 13.1375 15.4148 13.1069 15.4077C12.9538 15.374 12.7631 15.3323 12.6109 15.2679C7.45921 13.0916 3.81681 9.5031 1.7833 4.603C1.52666 3.98332 1.50233 3.41076 1.71502 2.94659C1.94498 2.44472 2.44806 2.05281 3.17167 1.81404C3.26271 1.7842 3.34512 1.76849 3.42282 1.76849C3.49267 1.76849 3.55859 1.78106 3.62374 1.80541C4.17312 2.0583 5.82833 4.27391 5.9233 4.86925C5.93114 4.99727 5.90211 5.1033 5.45867 5.39939C4.86534 5.79523 3.98005 6.70158 4.71858 8.33364C4.7382 8.37841 4.76253 8.42004 4.79078 8.46009L6.25136 10.543C6.28039 10.5838 6.31257 10.6223 6.34789 10.6569C7.05032 11.3378 7.90971 11.9056 8.90017 12.3462C9.95263 12.8175 10.9007 12.5779 11.4995 11.6889C11.702 11.3873 11.9061 11.2153 12.1062 11.1768C12.4099 11.1195 12.8039 11.337 13.0818 11.5286L13.3635 11.7219C13.7614 11.9936 14.1601 12.2661 14.5376 12.5646C14.6216 12.6313 14.7087 12.6957 14.795 12.7609C15.4637 13.262 15.4951 13.3618 15.3711 13.705Z" />
                  </svg>
                </a>
              </li>
              <li className="footer__social">
                <a
                  target="_blank"
                  href="https://t.me/"
                  className="social__links__link flex align-center justify-center tg"
                >
                  <svg
                    className="svg"
                    width={20}
                    height={20}
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0)">
                      <path d="M19.8512 0.801245C19.6781 0.607497 19.4051 0.555544 19.1789 0.654371L0.350925 8.8965C0.142332 8.9879 0.0052241 9.1922 0.000146014 9.42032C-0.00493207 9.64805 0.122801 9.85821 0.327487 9.95899L4.82316 12.1699L5.94346 18.891C5.97979 19.146 6.18018 19.3511 6.44111 19.3882C6.62861 19.4152 6.81494 19.3491 6.94384 19.2163L10.493 15.6671L15.5578 19.2851C15.7203 19.4007 15.9297 19.4261 16.1141 19.3531C16.2988 19.28 16.434 19.1179 16.4731 18.923L19.9887 1.30593C20.0234 1.13132 19.9785 0.944213 19.8512 0.801245ZM5.28721 11.0922L1.97669 9.46407L15.0555 3.73872L5.28721 11.0922ZM7.12079 12.834L6.57588 15.5586L5.98799 12.0312L13.1743 6.62151L7.28056 12.5351C7.19775 12.618 7.14267 12.7258 7.12079 12.834ZM7.47743 17.0253L8.09227 13.9523L9.52624 14.9765L7.47743 17.0253ZM15.5012 17.8042L8.59774 12.8734L18.4598 2.97857L15.5012 17.8042Z" />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width={20} height={20} />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </li>
              <li className="footer__social">
                <a
                  target="_blank"
                  href="https://wa.me/+94778321835"
                  className="social__links__link flex align-center justify-center whatsapp"
                >
                  <svg
                    className="svg"
                    width={20}
                    height={20}
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0)">
                      <path d="M10.001 0C4.49513 0 0.000996521 4.49374 0.000996521 9.99998C0.000996521 11.7379 0.514667 13.5 1.48927 15.1133L0.0345902 19.2183C-0.0404096 19.4304 0.0123246 19.6664 0.170527 19.8261C0.282246 19.9394 0.433027 20 0.586933 20C0.650605 20 0.714667 19.9898 0.776776 19.9683L4.92364 18.5488C6.51739 19.5121 8.22403 20 10.001 20C12.6615 20 15.1701 18.957 17.0639 17.0629C18.958 15.1691 20.001 12.6605 20.001 9.99998C20.001 4.49413 15.5072 0 10.001 0ZM10.001 18.8281C8.36075 18.8281 6.7834 18.3511 5.31231 17.4105C5.217 17.3496 5.10724 17.3183 4.99669 17.3183C4.93302 17.3183 4.86857 17.3289 4.80685 17.35L1.54435 18.4672L2.6881 15.239C2.74826 15.0687 2.72677 14.88 2.62951 14.7277C1.67638 13.2375 1.17287 11.6023 1.17287 9.99998C1.17287 5.13202 5.13302 1.17187 10.001 1.17187C14.8689 1.17187 18.8291 5.13202 18.8291 9.99998C18.8291 14.8679 14.8689 18.8281 10.001 18.8281Z" />
                      <path d="M16.1228 12.3991L14.2615 10.5382C14.0267 10.303 13.7142 10.1737 13.3822 10.1737C13.0497 10.1737 12.7372 10.303 12.5025 10.5382L12.0372 11.0034C11.899 11.1413 11.7158 11.2171 11.5208 11.2171C11.3259 11.2171 11.1423 11.1413 11.0044 11.0034L9.14309 9.14209C8.85872 8.85732 8.85872 8.39443 9.14309 8.10967L9.60872 7.64443C9.84348 7.40928 9.97278 7.09678 9.97278 6.76475C9.97278 6.43232 9.84348 6.11982 9.60872 5.88506L7.74739 4.02373C7.26223 3.53896 6.47317 3.53896 5.98802 4.02373L5.52278 4.48896C4.85677 5.15498 4.63489 6.56123 4.94348 8.15889C5.27708 9.8874 6.14388 11.5226 7.38411 12.7628C8.62395 14.0026 10.2591 14.8694 11.988 15.2034C13.149 15.4276 14.8275 15.4538 15.6575 14.6237L16.1228 14.1585C16.3579 13.9237 16.4872 13.6112 16.4872 13.2788C16.4873 12.9468 16.3579 12.6343 16.1228 12.3991ZM15.2943 13.33L14.829 13.7952C14.5111 14.1132 13.5091 14.3038 12.2099 14.0526C11.3404 13.8847 9.66497 13.3862 8.21263 11.9339C6.76028 10.4815 6.26184 8.80615 6.09388 7.93662C6.01341 7.51982 5.97825 7.13389 5.97825 6.79131C5.97825 6.06514 6.13567 5.53389 6.3513 5.31787L6.81692 4.85264C6.83099 4.83857 6.84934 4.83154 6.8677 4.83154C6.88606 4.83154 6.90481 4.83857 6.91888 4.85264L8.77981 6.71357C8.79388 6.72764 8.80091 6.74482 8.80091 6.76475C8.80091 6.78467 8.79388 6.80146 8.77981 6.81553L8.31458 7.28115C7.57317 8.02256 7.57317 9.2292 8.31458 9.9706L10.1759 11.8319C10.5349 12.1913 11.0126 12.389 11.5208 12.389C12.0286 12.389 12.5064 12.1913 12.8658 11.8319L13.331 11.3667C13.3591 11.3386 13.4048 11.3386 13.4329 11.3667L15.2943 13.228C15.3083 13.2421 15.3154 13.2593 15.3154 13.2788C15.3154 13.2987 15.3084 13.3159 15.2943 13.33Z" />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width={20} height={20} fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </li>
              <li className="footer__social">
                <a
                  target="_blank"
                  href="https://www.facebook.com/valmaxdigital"
                  className="social__links__link flex align-center justify-center facebook"
                >
                  <svg
                    className="svg"
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    viewBox="0 0 20 20"
                  >
                    <g clipPath="url(#clip0)">
                      <path d="M11.3506 19.9609H8.14155C7.60553 19.9609 7.1695 19.5258 7.1695 18.9908V11.7667H5.29725C4.76123 11.7667 4.3252 11.3313 4.3252 10.7965V7.70096C4.3252 7.16599 4.76123 6.7308 5.29725 6.7308H7.1695V5.18066C7.1695 3.64365 7.65308 2.33597 8.5678 1.39923C9.48664 0.458221 10.7707 -0.0390625 12.2813 -0.0390625L14.7287 -0.0350952C15.2638 -0.0341797 15.699 0.401001 15.699 0.935059V3.8092C15.699 4.34418 15.2632 4.77936 14.7273 4.77936L13.0795 4.77997C12.5769 4.77997 12.449 4.88052 12.4216 4.91135C12.3765 4.96246 12.3228 5.10696 12.3228 5.50598V6.73065H14.6034C14.7751 6.73065 14.9415 6.77292 15.0844 6.85257C15.3928 7.02454 15.5845 7.3497 15.5845 7.70111L15.5833 10.7967C15.5833 11.3313 15.1473 11.7665 14.6112 11.7665H12.3228V18.9908C12.3228 19.5258 11.8867 19.9609 11.3506 19.9609ZM8.34428 18.7885H11.1479V11.2418C11.1479 10.8846 11.4392 10.594 11.7969 10.594H14.4085L14.4096 7.90329H11.7968C11.439 7.90329 11.1479 7.61276 11.1479 7.25555V5.50598C11.1479 5.04791 11.1945 4.52698 11.541 4.13544C11.9596 3.66211 12.6193 3.60748 13.0792 3.60748L14.5243 3.60687V1.13709L12.2803 1.13342C9.85281 1.13342 8.34428 2.68433 8.34428 5.18066V7.25555C8.34428 7.61261 8.05318 7.90329 7.69543 7.90329H5.49998V10.594H7.69543C8.05318 10.594 8.34428 10.8846 8.34428 11.2418V18.7885ZM14.7264 1.13739H14.7265H14.7264Z" />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width={20} height={20} fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </li>
              <li className="footer__social">
                <a
                  target="_blank"
                  href="https://www.instagram.com/valmax.digital/"
                  className="social__links__link flex align-center justify-center instagram"
                >
                  <svg
                    className="svg"
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    viewBox="0 0 20 20"
                  >
                    <path d="M14.5862 0H5.41381C2.42858 0 0 2.42858 0 5.41381V14.5863C0 17.5714 2.42858 20 5.41381 20H14.5863C17.5714 20 20 17.5714 20 14.5863V5.41381C20 2.42858 17.5714 0 14.5862 0V0ZM18.8275 14.5863C18.8275 16.9249 16.9249 18.8275 14.5862 18.8275H5.41381C3.0751 18.8275 1.17248 16.9249 1.17248 14.5863V5.41381C1.17248 3.0751 3.0751 1.17248 5.41381 1.17248H14.5863C16.9249 1.17248 18.8275 3.0751 18.8275 5.41381V14.5863Z" />
                    <path d="M9.99984 4.53137C6.9844 4.53137 4.53125 6.98452 4.53125 9.99996C4.53125 13.0154 6.9844 15.4685 9.99984 15.4685C13.0153 15.4685 15.4684 13.0154 15.4684 9.99996C15.4684 6.98452 13.0153 4.53137 9.99984 4.53137ZM9.99984 14.2961C7.63107 14.2961 5.70373 12.3689 5.70373 9.99996C5.70373 7.63119 7.63107 5.70386 9.99984 5.70386C12.3688 5.70386 14.2959 7.63119 14.2959 9.99996C14.2959 12.3689 12.3688 14.2961 9.99984 14.2961Z" />
                    <path d="M15.5993 2.58923C14.7082 2.58923 13.9834 3.31418 13.9834 4.20513C13.9834 5.09624 14.7082 5.82119 15.5993 5.82119C16.4904 5.82119 17.2154 5.09624 17.2154 4.20513C17.2154 3.31402 16.4904 2.58923 15.5993 2.58923ZM15.5993 4.64855C15.3549 4.64855 15.1559 4.44958 15.1559 4.20513C15.1559 3.96054 15.3549 3.76171 15.5993 3.76171C15.8439 3.76171 16.0429 3.96054 16.0429 4.20513C16.0429 4.44958 15.8439 4.64855 15.5993 4.64855Z" />
                  </svg>
                </a>
              </li>
              <li className="footer__social">
                <a
                  target="_blank"
                  href="https://www.behance.net/valmaxdigitalagency"
                  className="social__links__link flex align-center justify-center behance"
                >
                  <svg
                    className="svg"
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <g clipPath="url(#clip2)">
                      <path d="M0.625 15.8333H6.535C10.3283 15.8333 11.6442 10.8024 8.29083 8.99742C10.5417 7.03575 9.14833 3.33325 6.12833 3.33325H0.625C0.28 3.33325 0 3.61325 0 3.95825V15.2083C0 15.5533 0.28 15.8333 0.625 15.8333ZM1.25 4.58325H6.12833C8.80417 4.58325 8.8075 8.55658 6.12833 8.55658H1.25V4.58325ZM1.25 9.80658H6.535C9.7525 9.80658 9.7525 14.5833 6.535 14.5833H1.25V9.80658Z" />
                      <path d="M15.3117 6.54761C12.5959 6.54761 10.5159 8.95428 10.6159 10.8468C10.41 13.5801 12.6134 15.8334 15.3117 15.8334C16.9884 15.8334 18.5517 14.9459 19.3909 13.5159C19.81 12.8018 18.7317 12.1718 18.3134 12.8826C17.6975 13.9318 16.5467 14.5834 15.3117 14.5834C13.4892 14.5834 12.0075 13.1934 11.8742 11.4409C13.0767 11.4551 15.3209 11.2843 19.3634 11.4134C19.3767 11.4134 19.3859 11.4068 19.3984 11.4068C21.065 11.3318 19.2409 6.54761 15.3117 6.54761ZM12.0384 10.1293C13.11 6.97177 17.6367 7.06594 18.6134 10.1826C15.2267 10.2634 13.2 10.1276 12.0384 10.1293Z" />
                      <path d="M17.7571 5.38672C18.5846 5.38672 18.5838 4.13672 17.7571 4.13672H12.8654C12.0379 4.13672 12.0388 5.38672 12.8654 5.38672H17.7571Z" />
                    </g>
                    <defs>
                      <clipPath id="clip2">
                        <rect width={20} height={20} fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </li>
              <li className="footer__social">
                <a
                  target="_blank"
                  href="https://dribbble.com/valmax"
                  className="social__links__link flex align-center justify-center dribble"
                >
                  <svg
                    className="svg"
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path d="M10.0391 20C15.5441 20 20 15.5449 20 10.0391C20 4.55338 15.5627 0 10.0391 0C4.53842 0 0 4.53537 0 10.0391C0 15.5676 4.55887 20 10.0391 20ZM4.89777 17.196C5.62393 14.4287 8.21075 12.1771 11.5195 11.1801C12.2954 13.4692 12.7744 15.8868 12.9198 18.3427C12.0169 18.6568 11.0477 18.8281 10.0391 18.8281C8.12408 18.8281 6.34888 18.2231 4.89777 17.196ZM14.062 17.8519C13.8873 15.4622 13.4207 13.131 12.6697 10.8907C14.7089 10.4758 16.8349 10.5539 18.7666 11.0764C18.4177 14.0306 16.5982 16.5407 14.062 17.8519ZM11.6005 8.1781C13.5887 7.43713 15.6967 6.20834 16.8431 4.43268C18.0524 5.92346 18.7901 7.81738 18.8261 9.88098C16.7473 9.35959 14.4675 9.30405 12.2685 9.7728C12.0557 9.21692 11.8405 8.70499 11.6005 8.1781ZM16.0126 3.54111C15.0613 5.26169 12.9309 6.44562 11.0899 7.11792C10.0595 5.09094 8.79898 3.24188 7.32529 1.59714C8.181 1.32141 9.09286 1.17188 10.0391 1.17188C12.3436 1.17188 14.4434 2.07184 16.0126 3.54111ZM6.16516 2.06406C7.6506 3.6644 8.92303 5.49728 9.95209 7.48779C7.38205 8.22647 4.36798 8.47885 1.38168 8.11966C1.9725 5.45273 3.76755 3.23349 6.16516 2.06406ZM1.20483 9.27887C4.40491 9.66904 7.66998 9.39316 10.4735 8.5553C10.7024 9.05197 10.9169 9.55704 11.116 10.0699C7.89597 11.046 4.9791 13.2451 3.91632 16.3907C2.22626 14.7894 1.17188 12.534 1.17188 10.0391C1.17188 9.78302 1.18347 9.52957 1.20483 9.27887Z" />
                  </svg>
                </a>
              </li>
              <li className="footer__social">
                <a
                  target="_blank"
                  href="https://clutch.co/profile/valmax-digital"
                  className="social__links__link flex align-center justify-center clutch"
                >
                  <svg
                    className="svg"
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    viewBox="0 0 50 50"
                    fill="none"
                  >
                    <path
                      d="M37.8015 36.7287L36.8797 37.5897C36.8794 37.5899 36.8792 37.5902 36.8789 37.5904C33.8442 40.4098 29.7109 41.976 25.262 41.976C15.7145 41.976 8.5 34.7282 8.5 25.13C8.5 15.4956 15.5347 8.5 25.262 8.5C29.6243 8.5 33.7178 10.0648 36.7969 12.8451L36.7971 12.8452L37.7562 13.7106L33.0624 18.4026L32.2036 17.6641C32.203 17.6635 32.2024 17.663 32.2018 17.6624C30.317 16.0107 27.8536 15.148 25.264 15.148C22.2998 15.148 19.7565 16.1687 17.9524 17.9505C16.1479 19.7327 15.114 22.246 15.114 25.172C15.114 31.0566 19.4223 35.368 25.264 35.368C27.9042 35.368 30.3249 34.4591 32.1636 32.8064C32.1638 32.8062 32.1641 32.806 32.1643 32.8058L33.0292 32.0348L37.8015 36.7287Z"
                      stroke="#C8C8C8"
                    />
                    <path
                      d="M25 19.476C26.534 19.476 28.0052 20.0854 29.0899 21.1701C30.1746 22.2548 30.784 23.726 30.784 25.26C30.784 26.794 30.1746 28.2652 29.0899 29.3499C28.0052 30.4346 26.534 31.044 25 31.044C23.466 31.044 21.9948 30.4346 20.9101 29.3499C19.8254 28.2652 19.216 26.794 19.216 25.26C19.216 23.726 19.8254 22.2548 20.9101 21.1701C21.9948 20.0854 23.466 19.476 25 19.476"
                      fill="#FF3D2E"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </footer>


        {/* Footer End */}
      </div>
    </div>
  );
}
