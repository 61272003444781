import React from 'react'
// import RecentProjects from '.';
// import Image1 from '../../assets/images/app1.jpg'
import Image_1 from '../../assets/works/1.png';
import Image_2 from '../../assets/works/2.png';
import Image_3 from '../../assets/works/3.png';
import Image_4 from '../../assets/works/4.png';

const RecentProjectsData = [
  {
    image:
      Image_1,
    name: 'Optimo Investments',
    desc: 'Development of the Trading and investment Company ',
    more: {
      clientName: 'THE CLIENT',
      heading: 'Development of the Trading and investment Company',
      desc1:
        'Development of the Trading and investment Company',
      image: '',
      color: {
        header: 'Avenir Next',
        colorSet: ['light', 'regular', 'bold'],
        seeckers: {
          color1: '#5F36C4',
          color2: '#FFFFFF',
          color3: '#4F5F6B',
          color4: '#303030',
        },
      },
      logoSection: {
        oldLogo: {
          name: 'Old Logo',
          logo: '',
        },
        newLogo: {
          name: 'New Logo',
          logo: '',
        },
      },
      inversation: {
        desc:
          'Shortly after the rebranding of forwarding company, the client wanted to create a company image by developing the website. But after developing MVP we had to face a challenge - we needed to develop such a site that would be used as efficiently as possible for further SEO-promotion. We also needed to increase its conversity and keep the current beautiful design at the same time',
        des2:
          'Our designers paid extra attention to graphic elements and images. Every one of them was carefully thought through and created in uniform style. The website has more than 40 graphic illustrations and icons. Some of them are fully animated',
        image: '',
      },
      images: {
        image1: '',
        image2: '',
        image3: '',
      },
      result: {
        desc:
          'As a result, genuine constructor of thematic landing pages was created. The website turned out to be colorful, stylish and fully corresponding with the brand of the forwarding company. Thanks to ongoing work of SEO specialist throughout the whole development process — we were 100% ready for SEO promotion, which we started straight after the project release',
        des2:
          'Overall, 56 custom elements were created. 180 working hours were spent on the project development. The following team was working on the project: Project manager, UI/UX Designer, Full Stack Web Developer PHP, QA Engineer, SEO Specialist, SEO Copywriter',
      },
    },
  },

  {
    image:
      Image_2,
    name: 'BranderMine',
    desc: 'Development of the Logo and Name Generator Company',
    more: {
      clientName: 'THE CLIENT',
      heading: 'The website development for forwarding company',
      desc1:
        'The company Star Trans Logistics offers wide range of services related to transportation, customs clearance and freight forwarding all over Ukraine. The company has been on the market since 2006. The main area of expertise — vehicles from the USA. The company also offers advantageous partnership terms for car dealers',
      image: '',
      color: {
        header: 'Avenir Next',
        colorSet: ['light', 'regular', 'bold'],
        seeckers: {
          color1: '#5F36C4',
          color2: '#FFFFFF',
          color3: '#4F5F6B',
          color4: '#303030',
        },
      },
      logoSection: {
        oldLogo: {
          name: 'Old Logo',
          logo: '',
        },
        newLogo: {
          name: 'New Logo',
          logo: '',
        },
      },
      inversation: {
        desc:
          'Shortly after the rebranding of forwarding company, the client wanted to create a company image by developing the website. But after developing MVP we had to face a challenge - we needed to develop such a site that would be used as efficiently as possible for further SEO-promotion. We also needed to increase its conversity and keep the current beautiful design at the same time',
        des2:
          'Our designers paid extra attention to graphic elements and images. Every one of them was carefully thought through and created in uniform style. The website has more than 40 graphic illustrations and icons. Some of them are fully animated',
        image: '',
      },
      images: {
        image1: '',
        image2: '',
        image3: '',
      },
      result: {
        desc:
          'As a result, genuine constructor of thematic landing pages was created. The website turned out to be colorful, stylish and fully corresponding with the brand of the forwarding company. Thanks to ongoing work of SEO specialist throughout the whole development process — we were 100% ready for SEO promotion, which we started straight after the project release',
        des2:
          'Overall, 56 custom elements were created. 180 working hours were spent on the project development. The following team was working on the project: Project manager, UI/UX Designer, Full Stack Web Developer PHP, QA Engineer, SEO Specialist, SEO Copywriter',
      },
    },
  },

  {
    image:
      Image_3,
    name: 'NorthShore Learning Center',
    desc: 'Development of the Child Care web site in Australia',
    more: {
      clientName: 'THE CLIENT',
      heading: 'The website development for forwarding company',
      desc1:
        'The company Star Trans Logistics offers wide range of services related to transportation, customs clearance and freight forwarding all over Ukraine. The company has been on the market since 2006. The main area of expertise — vehicles from the USA. The company also offers advantageous partnership terms for car dealers',
      image: '',
      color: {
        header: 'Avenir Next',
        colorSet: ['light', 'regular', 'bold'],
        seeckers: {
          color1: '#5F36C4',
          color2: '#FFFFFF',
          color3: '#4F5F6B',
          color4: '#303030',
        },
      },
      logoSection: {
        oldLogo: {
          name: 'Old Logo',
          logo: '',
        },
        newLogo: {
          name: 'New Logo',
          logo: '',
        },
      },
      inversation: {
        desc:
          'Shortly after the rebranding of forwarding company, the client wanted to create a company image by developing the website. But after developing MVP we had to face a challenge - we needed to develop such a site that would be used as efficiently as possible for further SEO-promotion. We also needed to increase its conversity and keep the current beautiful design at the same time',
        des2:
          'Our designers paid extra attention to graphic elements and images. Every one of them was carefully thought through and created in uniform style. The website has more than 40 graphic illustrations and icons. Some of them are fully animated',
        image: '',
      },
      images: {
        image1: '',
        image2: '',
        image3: '',
      },
      result: {
        desc:
          'As a result, genuine constructor of thematic landing pages was created. The website turned out to be colorful, stylish and fully corresponding with the brand of the forwarding company. Thanks to ongoing work of SEO specialist throughout the whole development process — we were 100% ready for SEO promotion, which we started straight after the project release',
        des2:
          'Overall, 56 custom elements were created. 180 working hours were spent on the project development. The following team was working on the project: Project manager, UI/UX Designer, Full Stack Web Developer PHP, QA Engineer, SEO Specialist, SEO Copywriter',
      },
    },
  },
  {
    image:
      Image_4,
    name: 'Electroligence Pvt LTD',
    desc: 'Development of the industrial-grade  Machine Downtime monitoring Hardware firmware.',
    more: {
      clientName: 'THE CLIENT',
      heading: 'The website development for forwarding company',
      desc1:
        'The company Star Trans Logistics offers wide range of services related to transportation, customs clearance and freight forwarding all over Ukraine. The company has been on the market since 2006. The main area of expertise — vehicles from the USA. The company also offers advantageous partnership terms for car dealers',
      image: '',
      color: {
        header: 'Avenir Next',
        colorSet: ['light', 'regular', 'bold'],
        seeckers: {
          color1: '#5F36C4',
          color2: '#FFFFFF',
          color3: '#4F5F6B',
          color4: '#303030',
        },
      },
      logoSection: {
        oldLogo: {
          name: 'Old Logo',
          logo: '',
        },
        newLogo: {
          name: 'New Logo',
          logo: '',
        },
      },
      inversation: {
        desc:
          'Shortly after the rebranding of forwarding company, the client wanted to create a company image by developing the website. But after developing MVP we had to face a challenge - we needed to develop such a site that would be used as efficiently as possible for further SEO-promotion. We also needed to increase its conversity and keep the current beautiful design at the same time',
        des2:
          'Our designers paid extra attention to graphic elements and images. Every one of them was carefully thought through and created in uniform style. The website has more than 40 graphic illustrations and icons. Some of them are fully animated',
        image: '',
      },
      images: {
        image1: '',
        image2: '',
        image3: '',
      },
      result: {
        desc:
          'As a result, genuine constructor of thematic landing pages was created. The website turned out to be colorful, stylish and fully corresponding with the brand of the forwarding company. Thanks to ongoing work of SEO specialist throughout the whole development process — we were 100% ready for SEO promotion, which we started straight after the project release',
        des2:
          'Overall, 56 custom elements were created. 180 working hours were spent on the project development. The following team was working on the project: Project manager, UI/UX Designer, Full Stack Web Developer PHP, QA Engineer, SEO Specialist, SEO Copywriter',
      },
    },
  },
]

export default RecentProjectsData
