import React, { useState, useEffect } from "react";

import squa from "../../assets/img/2b34a13fe045cf7901a224f19a15c9d8.svg";
import del from "../../assets/img/7d39b955139466e8a5d20690d6dbe143.svg";
import Navbar from "../../components/Navbar/Navbar";
import logo_footer from "../../assets/icons/fortxcore_Black.svg";

import sql_2 from "../../assets/img/d84fd2e286f18ef16d7107724229ef55.svg";


import logo from "../../assets/img/logo.svg";

import optimo from "../../assets/works/optimo.png";
import northshore from "../../assets/works/northshore.png";
import pillpak from "../../assets/works/pillpack.png";
import boylebeer from "../../assets/works/boylebeer.png";

// import cover_one from "https://valmaxdigital.com.ua/dist/public/img/portfolio/en/slide_9.baf4f13.png";

export default function WorksPage() {
  const [sidebar, setSidebar] = useState(false);
  const [scroll, setScroll] = useState(false);

  // useEffect(() => {
  //   Aos.init({
  //     duration: 1000,
  //     once:true,
  //     delay:50,
  //   });
  // }, []);

  const showSidebar = () => setSidebar(!sidebar);
  const handlescrolling = () => {
    if (window.scrollY >= 10) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  window.addEventListener("scroll", handlescrolling);

  return (
    <div>
      <div clasName="mainSection flex direction-column mainSection__portfolio"
      >
        
        <div
          className="flex direction-column view portfolio"
          style={{overflow:"hidden"}}
        >
          <Navbar
          showSidebar={showSidebar}
          sidebar={sidebar}
            hide_button={true}
            headerblack
        />
          <section className="landingSection">
            {/* <ul className="dots--wrap runAnimation fadeIn d-2 portfolio__dots run">
              <li className="dots__line flex">
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
              </li>
              <li className="dots__line flex">
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
              </li>
              <li className="dots__line flex">
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
              </li>
              <li className="dots__line flex">
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
              </li>
              <li className="dots__line flex">
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
              </li>
            </ul> */}

            <object
              data={squa}
              type="image/png"
              className="figure2 figure mounted"
            />
            <object
              data={del}
              type="image/png"
              className="figure3 figure mounted"
            />
            <div className="wrapper flex direction-column align-start wrapper__main">
              <h1 className="landingSection__title flex align-center runAnimation fadeInBottomRight run">
                works
              </h1>
              {/**/}
              <h2 className="landingSection__head runAnimation fadeInUp d-1 run">
                Our works
              </h2>
              {/**/}
              <div className="portfolio__item--wrap flex wrap">
                {/* 1st start */}
                <div className="portfolio__item flex direction-column align-start runAnimation fadeInUp run">

                  
                  <figure className="portfolio__item__img--wrap pointer isLink">
                    <img
                      src={optimo}
                      alt="Star trans logistic"
                      className="portfolio__item__img isLink"
                    />
                  </figure>
                  <a
                    href="/en/portfolio/startranslog"
                    className="portfolio__item__title animateLink"
                    style={{
                      fontFamily: "PT Serif Caption",
                      fontWeight: "400",
                    }}
                  >
                    Optimo Investment <br /> (
                    <span
                      style={{
                        color: "#0084FF",
                        textTransform: "lowercase",
                        fontFamily: "PT Serif Caption",
                      }}
                    >
                      www.optimoinvest.com
                    </span>
                    )
                  </a>
                  <p
                    className="portfolio__item__group"
                    style={{ fontFamily: "M PLUS 1p", lineHeight: "33px" }}
                  >
                    Optimo Invest is an Investment company based in the USA.
                    Optimo provides short- & long-term investment plans for
                    potential investors all around the world.
                    <br />
                    &nbsp;
                    <br />
                    The requirement of Optimo was extensive in terms of the
                    project scope. The website of Optimo has two functionalities
                    as the corporate website & a web investment portal.
                    FortXcore was able to take on this project with two mobile
                    apps on iOS & Android platforms till completion.
                  </p>
                </div>
                {/* 1st start end */}

                {/* 2nd Works Start */}
                <div className="portfolio__item flex direction-column align-start runAnimation fadeInUp run">
                  <figure className="portfolio__item__img--wrap pointer isLink">
                    <img
                      src={northshore}
                      alt="FARTUM"
                      className="portfolio__item__img isLink works_img"
                    />
                  </figure>
                  <a
                    href="/en/portfolio/fartum"
                    className="portfolio__item__title animateLink"
                    style={{
                      fontFamily: "PT Serif Caption",
                      fontWeight: "400",
                    }}
                  >
                    NORTHSHORE Learning Center <br /> (
                    <span
                      style={{
                        color: "#0084FF",
                        textTransform: "lowercase",
                       
                      }}
                    >
                      www.northshorelearningcenter.com
                    </span>
                    )
                  </a>
                  <p
                    className="portfolio__item__group"
                    style={{ fontFamily: "M PLUS 1p", lineHeight: "33px" }}
                  >
                    Children aged 6 weeks to 12 years are cared for in a safe
                    and supportive environment at northshore Learning Center.
                    Nursery, Toddler Program, Preschool Program, Kindergarten
                    Readiness Class, and Distance Learning Room are all
                    available.
                    <br />
                    &nbsp;
                    <br />
                    Northshore required a website that portraits its target
                    segment & brand personality. The challenge was tricky as the
                    UI necessary to have exclusive components to it. However,
                    FortXcore aced the project by presenting this masterpiece!
                  </p>
                </div>
                {/* 2nd works */}

                {/* 3nd Works Start */}
                <div className="portfolio__item flex direction-column align-start runAnimation fadeInUp run">
                  <figure className="portfolio__item__img--wrap pointer isLink">
                    <img
                      src={pillpak}
                      alt="FARTUM"
                      className="portfolio__item__img isLink works_img"
                    />
                  </figure>
                  <a
                    href="/en/portfolio/fartum"
                    className="portfolio__item__title animateLink"
                    style={{
                      fontFamily: "PT Serif Caption",
                      fontWeight: "400",
                    }}
                  >
                    Pill Pack <br /> (
                    <span
                      style={{
                        color: "#0084FF",
                        textTransform: "lowercase",
                        fontFamily: "PT Serif Caption",
                      }}
                    >
                      www.pillpack.lk
                    </span>
                    )
                  </a>
                  <p
                    className="portfolio__item__group"
                    style={{ fontFamily: "M PLUS 1p", lineHeight: "33px" }}
                  >
                    Pill pack is a medical services provider based in Sri Lanka
                    to provide Healthcare services and education solutions in
                    the same field. The Platform features an online learning
                    portal, a corporate website & an e-commerce platform
                    attached to the same business model.
                    <br />
                    &nbsp;
                    <br />
                    FortXcore was honored to be this mammoth project as it was
                    both challenging & exciting. The outcome was perfect & the
                    business is yet to make its mark in the medical industry.
                  </p>
                </div>
                {/* 3nd works end*/}

                {/* 4nd Works Start */}
                <div className="portfolio__item flex direction-column align-start runAnimation fadeInUp run">
                  <figure className="portfolio__item__img--wrap pointer isLink works_img">
                    <img
                      src={boylebeer}
                      alt="FARTUM"
                      className="portfolio__item__img isLink "
                    />
                  </figure>

                  <a
                    href="/en/portfolio/fartum"
                    className="portfolio__item__title animateLink"
                    style={{
                      fontFamily: "PT Serif Caption",
                      fontWeight: "400",
                    }}
                  >
                    Boyle Beer <br /> (
                    <span
                      style={{
                        color: "#0084FF",
                        textTransform: "lowercase",
                        fontFamily: "PT Serif Caption",
                      }}
                    >
                      www.boylebeer.co.uk
                    </span>
                    )
                  </a>
                  <p
                    className="portfolio__item__group works_text"
                    style={{ fontFamily: "M PLUS 1p", lineHeight: "33px" }}
                  >
                    Boyle Beer is an on-demand cleaning company established in
                    Toronto that connects individuals and businesses needing
                    skilled and committed cleaning services. All of Kleanr's
                    cleaning professionals have been thoroughly vetted, trained,
                    insured, and bonded.
                    <br />
                    &nbsp;
                    <br />
                    Boyle Beer approached FortXcore with the requirement of
                    designing & developing their corporate website, which also
                    acts as their virtual marketplace/store. FortXcore was able
                    to complete this project successfully & all the criteria
                    were met.
                  </p>
                </div>
                {/* 4nd works end*/}
              </div>


              
            </div>
          </section>

          {/* discusss */}
          <section className="feedback--wrap container" style={{maxWidth:"100%"}}>
            {/**/}
            <object
              data={sql_2}
              type="image/svg+xml"
              className="ellipse"
            />
            {/* <ul className="dots--wrap runAnimation fadeIn d-2 feedback__dots run">
              <li className="dots__line flex">
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
              </li>
              <li className="dots__line flex">
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
              </li>
              <li className="dots__line flex">
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
              </li>
              <li className="dots__line flex">
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
              </li>
            </ul> */}
            <div className="wrapper feedback flex align-center wrapper__main">
              <div className="flex direction-column feedback__text--wrap align-start">
                <p className="feedback__text runAnimation fadeInUp run">
                  Let’s explore your business opportunities together!
                </p>
                <button className="button button__gradient runAnimation fadeInUp run">
                  discuss the project
                </button>
              </div>
              <div className="flex align-center direction-column feedback__links runAnimation fadeInUp run">
                <a
                  target="_blank"
                  href="tel:+380660257838"
                  className="tel animateLink"
                >
                  +94 (011) 708 00 13
                </a>
                <a
                  target="_blank"
                  href="mailto:support@fortxcore.com "
                  className="mail animateLink"
                >
                  support@fortxcore.com
                </a>
              </div>
            </div>
          </section>
        </div>
{/* discuss end */}

        {/* Start Footer */}
        <footer className="footer--wrap">
          <span className="footer__line--wrap wrapper">
            <span className="footer__line" />
          </span>
          <div className="flex justify-between align-center footer">
            <div className="flex align-center footer__left">
              <span className="header__logo footer__logo">
                <div className="flex align-center">
                  <img
                    src={logo}
                    alt="fortxcore"
                    className="header__logo__part-1"
                  />
                  {/* <span className="brand__name__footer">fortxcore</span> */}
                  <img src={logo_footer} className="footer_logo"/>
                </div>
              </span>
              <p className="footer__copy" >
                © 2021 FortXcore Software 
              </p>
              <a
                href="https://valmaxdigital.com.ua/en/privacy"
                className="footer__link"
               
              >
                Privacy policy
              </a>
            </div>
          </div>
        </footer>
        {/* Footer End */}
        
      </div>
    </div>
  );
}
