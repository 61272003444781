import React from 'react'
import { useParams } from 'react-router';

export default function RecentProjectDetails({data}) {
    const { name } = useParams();
    return <div>
        {data.filter(RecentData => RecentData.name === name).map((data, i) => {
            return (
                <div key={i}>
                   <h1> {data.more.clientName}</h1>
                </div>
            )
        })}
    </div>;
}
