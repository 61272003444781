import React from 'react'
import '../Navbar/NavBar2.css'

export default function Button({name}) {
    return (
      <div>
        <button className="button button__gradient discussProject animate animate__right">
          {name}
        </button>
      </div>
    );
}
