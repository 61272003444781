import React,{useState,useEffect} from 'react';
import './App.css';
// import Navbar from './components/Navbar/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import Home from './pages/Home';
import LoadingScreen from './pages/loadingscreen';
import WorksPage from './pages/Works';
import BlogPage from './pages/Blog';
import ContactPage from './pages/Contact';
import AboutPage from './pages/About';
import HomePage from './pages/Home';
import RecentProjectDetails from './pages/Works/recentProjects';
import RecentProjectsData from './components/RecentProjects/RecentProjectsData';
// import Aos from "aos";
// import "aos/dist/aos.css";
// import RecentProjects from './components/RecentProjects/index'
// import RecentProjectSlider from './components/RecentProjects/index';
// import RecentProjectsData from './components/RecentProjects/RecentProjectsData';

function App() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      setTimeout(() => setLoading(false), 3500);
    }, []);
  return (
    <>
      
      {loading ? (<LoadingScreen />) :
        
        <div >
      
         <Router>
         
           <Switch>
              <Route path="/" exact component={HomePage} />
              <Route path="/about" component={AboutPage} />
            
              <Route path="/blog" component={BlogPage} />
              <Route path="/contact" component={ContactPage} />
              <Route path="/works/:name">
                <RecentProjectDetails data={RecentProjectsData} />
              </Route>
              <Route path="/works" component={WorksPage} />
           </Switch>
         </Router>
        </div >

      }
      
       {/* <Route path="/works" component={WorksPage} /> */}
     
    </>
  );
}

export default App;
