/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import './style.css'
import logo from "../../assets/img/logo.svg";


export default function LoadingScreen() {
  const [index, setIndex] = useState(0);
  const [loading, setloading] = useState(true)

  useEffect(() => {
    setTimeout(() => setloading(false), 3500);
    const intervel = setInterval(() => {
      setIndex((prv) => prv + 1);
    }, 500);
    return () => clearInterval(intervel);
    
  }, []);
  console.log(index);

  return (
    <div>
      <header className={loading? "App-header":"my-hide"}>
        <div className="svg_wrapper preloader__logo">


          <img src={logo} className="preloader__logo" />




     
        </div>
        <div className="preloader__slogan mounted">
          <div className="preloader__slogan__item">
            {index === 1 && <Design />}
          </div>
          <div className="preloader__slogan__item">
            {index === 2 && <Development />}
          </div>
          <div className="preloader__slogan__item">
            {index === 3 && <Marketing />}
          </div>
          <div className="preloader__slogan__item">
            {index === 4 && <FortXcore />}
          </div>
        </div>
      </header>
    </div>
  );
}

const Design = () => {
  return (
    <div>
      <h1 className="font-link child-name">Design</h1>
    </div>
  );
};
const Development = () => {
  return (
    <div>
      <h1 className="font-link child-name">App Development</h1>
    </div>
  );
};

const Marketing = () => {
  return (
    <div>
      <h1 className="font-link child-name">Marketing</h1>
    </div>
  );
};

const FortXcore = () => {
  return (
    <div>
      <h1 className="font-link main-name">FORTXCORE</h1>
    </div>
  );
};
